<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <img class="imgLogo" :src="logo">
    <div class="landingNav">
      <button class="btn btn-primary" @click="this.$parent.currPage=1">Software Development</button>
      <button class="btn btn-primary" @click="this.$parent.currPage=2">IT - Training</button>
      <button class="btn btn-primary" @click="this.$parent.currPage=5">Virtual Assistance</button>

    </div>
    <!-- <h3>Essential Links <i class="bi-alarm" style="font-size: 2rem; color: cornflowerblue;"></i></h3> -->

  </div>
</template>

<script>
export default {
  name: 'LandingComponent',
  
  

  props: {
    msg: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
    methods:{
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
.hello{
  display:flex;
  width:80vw;
  margin:100px auto;
  justify-content: center;
  flex-direction: column;
  gap:20px;
  }
.landingNav {
  width:100%;
  display: flex;
  justify-content: center;
  gap:20px;
}

a {
  color: #42b983;
}

img {
  height: 60vh;
  align-self: center;
  flex: 0 0 auto;

  
}</style>
