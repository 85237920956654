<template>
  <MainHeaderComponent msg="Contact"></MainHeaderComponent>
  <MainNavComponent></MainNavComponent>

  <div class="itemsNav">&nbsp;</div>
  <div v-if="showItem == 1" class="softDevItems">
    <div class="text-black pt-3 col-12">
      <h3>Get in touch</h3>
      <div class="row formRow">
        <label class="col-sm-2 col-12 text-sm-end text-start">Your Name</label>
        <input type="text" class="col" name="contactName" id="contactName">
      </div>
      <div class="row formRow">

        <label class="col-sm-2 col-12 text-sm-end text-start">Your Email</label>
        <input type="text" class="col" name="contactEmail" id="contactEmail" @change="chkEmail()">
      </div>
      <div class="row formRow">
        <label class="col-sm-2 col-12 text-sm-end text-start">Confirm Email</label>
        <input type="text" class="col border border-primary" name="contactEmailConf" id="contactEmailConf"
          @change="chkEmail()">
      </div>
      <div class="row formRow">
        <label class="col-sm-2 col-12 text-sm-end text-start">Message</label>
        <textarea id="contactMessage"></textarea>
        <button v-if="!sendSuccess" class="btn btn-primary mt-2" @click="sendMail">Send Message</button>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeaderComponent from './MainHeaderComponent.vue';
import MainNavComponent from './MainNavComponent.vue';

export default {
    name: 'ContactComponent',
    props: {
        msg: String
    }, data() {
        return {
            imgBaseDir: "/img/",
            logo: "/img/logo01.png",
            webdes: "/img/web_des.jpg",
            swdev: "/img/sw_dev.jpg",
            showItem: 1,
            sendSuccess: false,
        };
    },
    methods: {
        chkEmailReg(email) {
            let regex = new RegExp(/^([a-zA-Z0-9.-]+@[a-zA-Z0-9-.-]+\.[a-zA-Z]{2,})$/);
            if (regex.test(email.value)) {
                email.style.borderColor = "green";
                return true;
            }
            else {
                email.style.borderColor = "red";
                return false;
            }
        },
        chkEmail() {
            let email = document.getElementById("contactEmail");
            let emailConf = document.getElementById("contactEmailConf");
            email.style.backgroundColor = "white";
            emailConf.style.backgroundColor = "white";
            if (this.chkEmailReg(email)) {
                if (email.value === emailConf.value) {
                    console.log("checking");
                    emailConf.style.backgroundColor = "rgba(150,255,150, .2)";
                    return true;
                }
                else {
                    emailConf.style.backgroundColor = "rgba(255,150,150, .2)";
                    return false;
                }
            }
            else {
                return false;
            }
        },
        sendMail() {
            let contName = document.getElementById("contactName").value;
            let contEmail = document.getElementById("contactEmail").value;
            // let emailConf = document.getElementById("contactEmailConf").value;
            let contMessage = document.getElementById("contactMessage").value;
            var bodyLoad = JSON.stringify({ name: contName, email: contEmail, message: contMessage, referrer: this.$parent.source });
            if (this.chkEmail()) {
                console.log(bodyLoad);
                //TODO:  api call --> php --> sendmail oder db -->  alert(contName, email, emailConf, message);
                var requestOptions = {
                    method: 'PUT',
                    body: bodyLoad,
                    redirect: 'follow'
                };
                fetch(this.$parent.api, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                    console.log(result);
                    result = JSON.parse(result);
                    if (result.success == 200) {
                        this.sendSuccess = true;
                        document.getElementById("contactMessage").value = "Thank you for your message.\nI'll get back to you soon.";
                        console.log(result);
                    }
                    else {
                        console.log("Fehler bei der Übertragung");
                    }
                    //      
                });
                // .then(response=>{
                //   console.log(response.text());      
                // })
                // })
            }
        }
    },
    components: { MainNavComponent, MainHeaderComponent }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

li {
  text-align: left;
}

.softDevItems {

  margin: -40px auto;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  height: 50vh;

}

.itemsNav {
  margin: 60px auto;
  display: flex;
  justify-content: start;
  width: 80%;
  max-width: 800px;
  gap: 10px;
}

.formRow {
  width: 95%;
  padding: 5px;
  margin: 0 auto;
}

textarea {
  height: 200px;
}

a {
  color: #42b983;
}
</style>
