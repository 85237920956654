<!-- SlideShow.vue -->
<template>
    <div class="slideshow-container">
      <customer-review :review="currentReview" />
  
      <div class="slideshow-controls">
        <button class="btn btn-primary btn-sm" @click="previousSlide">Previous</button>
        <button class="btn btn-primary btn-sm" @click="nextSlide">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import CustomerReview from './CustomerReview.vue';
  
  export default {
    name: 'SlideShow',
    components: {
      CustomerReview
    },
    data() {
      return {
        currentIndex: 0,
        reviews: [      
          { name: 'John Doe, Small Business Owner, Web Development and Automation', text: "Working with Digital Leprechaun has been a game-changer for our business. Not only did they streamline our website maintenance and automate many of our office tasks, but their knowledge in IT and software development has helped us significantly improve efficiency. From automating repetitive processes to troubleshooting technical issues, they've been an invaluable asset." },
          { name: 'Jane Smith, E-commerce Manager, Online Store Management and Customer Support', text: "Their ability to organize our calendars, manage data entry, and keep track of important deadlines has been crucial to our operations. Additionally, their expertise in photo and video editing helped us create polished materials for our courses, and their troubleshooting skills saved us in many tight situations." },
          { name: 'Michael Brown, Marketing Director, Creative and GDPR Compliance', text: "Digital Leprechaun has an incredible ability to handle diverse tasks. From managing our marketing giveaways to ensuring GDPR compliance, they’ve provided end-to-end support that we could always rely on. Their creative input and thorough approach to project management have helped us launch multiple successful initiatives." },
          { name: 'Charlie Brown, E-commerce Manager, Online Store Management and Customer Support', text: "We needed someone who could handle not only customer support but also manage our online store inventory and order processing. Digital Leprechaun exceeded our expectations by providing flawless email management, assisting with competitor analysis, and even translating marketing materials from German to English. Their attention to detail is remarkable, and they bring a fresh, unbiased perspective that we truly value." },
          // You can add more reviews here or load them dynamically
        ]
      };
    },
    computed: {
      currentReview() {
        return this.reviews[this.currentIndex];
      }
    },
    mounted() {
  this.autoSlide = setInterval(() => {
    this.nextSlide();
  }, 5000); // Change slide every 5 seconds
},
beforeUnmount() {
  clearInterval(this.autoSlide);
},
    methods: {
      nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.reviews.length;
      },
      previousSlide() {
        this.currentIndex = (this.currentIndex - 1 + this.reviews.length) % this.reviews.length;
      }
    }
  };
  </script>
  
  <style scoped>
  .slideshow-container {
    position: relative;
    width: 100%;
    height:100%;
    max-width: 600px;
    margin: auto;
  }
  
  .slideshow-controls {
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    bottom: 10px;
  }
  

  </style>
  