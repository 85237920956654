<template>
  <header>
    <div class="col-2"><img class="imgLogo" :src="logo" @click="this.$root.currPage = 0">
    </div>
    <div class="col-md-8 col-10">
      <h1 class="col mt-2 mt-sm-4 me-sm-4">Digital Leprechaun</h1>
      <h3 class="col mt-2 mt-sm-4 me-sm-4">{{msg}}</h3>
      <div class="landingNav row d-flex justify-content-around">

      </div>
    </div>
    <div class="col-md-2"></div>
    </header>
</template>

<script>
export default {
  name: 'MainHeaderComponent',
  
  

  props: {
    msg: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
    methods:{
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header h1,
header h3 {
  color: aliceblue;
}

.pageNav {
  gap:5px;
}
.landingNav {
  width:100%;
  display: flex;
  justify-content: center;
  gap:20px;
}
header {
  display: flex;
  width: 80vw;
  margin: 20px auto;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  gap: 20px;
}

header img {
  height: 15vh;
  align-self: center;
  flex: 0 0 auto;
}
</style>
