<!-- CustomerReview.vue -->
<template>
  <div class="customer-review">
    <p>&quot;{{ review.text }}&quot;</p>
    <i>{{ review.name }}</i>
  </div>
</template>

<script>
export default {
  name: 'CustomerReview',
  props: {
    review: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.customer-review {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
}
</style>
