<template>
  <MainHeaderComponent :msg = msg></MainHeaderComponent>
  <MainNavComponent></MainNavComponent>

  <div v-if="showItem == 1">
    <SWNavComponent></SWNavComponent>
    <div class="softDevItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="webdes">
      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>Websites</h3>
        <p>Connectivity is key to success in business. You'll never know how many potential customers just did not find
          you when looking for your services or products.</p>
        <p>A website may provide that missing link to engage with your new or existing customers.</p>
        <h3>Don't put your chances at risk.</h3>
        <p> I am here to help with your initial online-presence, any website update or a full-relaunch.
        </p>
      </div>
    </div>
  </div>
  <div v-if="showItem == 2">
    <SWNavComponent></SWNavComponent>
    <div class="softDevItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="swdev">
      </div>
      <!-- <div class="text-black pt-3 col-sm-6 col-12"> -->
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>Application Development</h3>
        <ul>
          <li>small scripts for automation</li>
          <li>a functionality, not yet available in your other software</li>
          <li>a fully-grown software e.g. to manage supplies and customers</li>
        </ul>
        <p>With broad knowledge about technology and plenty of
          experience in multiple coding languages, I know that almost everything is possible.</p>
        <p> I am glad to listen to your digital needs, offer my coding skills to fulfill them, or at least guide you into
          the right direction.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeaderComponent from './MainHeaderComponent.vue';
import MainNavComponent from './MainNavComponent.vue';
import SWNavComponent from './SWNavComponent.vue';

export default {
    name: 'SoftDevComponent',
    props: {

    }, data() {
        return {
            imgBaseDir: "/img/",
            logo: "/img/logo01.png",
            webdes: "/img/web_des.jpg",
            swdev: "/img/sw_dev.jpg",
            showItem: 1,
            activePage:"1",
            msg:"Software Development"
        };
    },
    methods: {},
    components: { MainNavComponent, MainHeaderComponent, SWNavComponent }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

li {
  text-align: left;
}

.softDevItems {

  margin: -40px auto;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  height: 50vh;

}

.imgWebdes {
  height: inherit;
  align-self: flex-start;

}

.itemBox {
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: start;
}

.textBox {
  padding: 5px;
  background-color: none;
}</style>
