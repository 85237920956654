<template>

  <LandingComponent v-if="currPage==0" msg=""/>
  <SoftDevComponent v-if="currPage==1" />
  <ITTrainingComponent v-if="currPage==2"/>
  <BlogComponent v-if="currPage==4"></BlogComponent>
  <VaComponent v-if="currPage==5"></VaComponent>
  <ContactComponent v-if="currPage==3" msg="IT Training"/>
</template>

<script>
import LandingComponent from './components/LandingComponent.vue'
import SoftDevComponent from './components/SoftDevComponent.vue'
import ITTrainingComponent from './components/ITTrainingComponent.vue'
import ContactComponent from './components/ContactComponent.vue'
import BlogComponent from './components/BlogComponent.vue'
import VaComponent from './components/VaComponent.vue'

export default {
  name: 'App',
  components: {
    LandingComponent,
    SoftDevComponent,
    ITTrainingComponent,
    ContactComponent,
    BlogComponent,
    VaComponent
},
created() {
    let uri = window.location.search.substring(1); 
    if (uri.split("=")[0]=="adid"){this.source=uri.split("=")[1];}
    console.log(this.source);
  },

  data(){
    return{
    imgBaseDir:"/img/",
    logo:"/img/logo01.png",
    currPage:0,
    source:"",
    api:'https://digital-leprechaun.com/api/digi_api.php',
    }
  },

}
</script>

<style>
body{
  background-color: black;
  padding:20px;
}
#app {
  background-color: black;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width:100vw;
  height:100vh;
  border: 0px solid blue;
  margin:0 auto;
}
.imgLogo{
  height:60vh;
}
</style>
