<template>
  <MainHeaderComponent msg="IT Training" active="2"></MainHeaderComponent>
 <MainNavComponent></MainNavComponent>


  <div v-if="showItem == 1">
    <ItemNavComponent></ItemNavComponent>
    
    <div class="softDevItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="basic_skills">
      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>Basic Skills</h3>
        <p>Knowing how to use computers has become a necessity in our daily lifes.</p>
        <p>Learning these skills is not restricted to any age, gender or origin. Finding the right training, can be
          difficult, though.</p>
        <h3>Let me help.</h3>
        <p>All training is individually tailored to your knowledge, age and needs. </p>
        <p>Available topics include: PC Essentials, Emails, Internet, Online-Safety, Office-Essentials, Video-Cut,
          Photo-Manipulation, Easy Sound-Recording, Digital Crafting, ...
        </p>
      </div>
    </div>
  </div>

  <div v-if="showItem == 2">
    <ItemNavComponent></ItemNavComponent>
    <div class="softDevItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="it_prof">
      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>IT Proficiency</h3>
        <p>Many of us use some software for work every day.</p>
        <p>Being proficient does not only mean to know how to get a job done. It's made up of knowing what is possible,
          how to make things easier and having acquired the experience to optimize processes.</p>
        <h3>Proficiency = less time, fewer mistakes, more success</h3>
        <p>I can offer trainings for the full office suite and many other applications. Ask me.
        </p>
      </div>
    </div>
  </div>

  <div v-if="showItem == 3">
    <ItemNavComponent></ItemNavComponent>
    <div class="softDevItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="coding_start">
      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>Coding Basics</h3>
        <p>Starting to write your own code isn't very complicated.</p>
        <p>A few standard rules, some vocabulary and the understandig of some basic principles is all it takes to get
          started.</p>
        <h3>Coding is fun !</h3>
        <p>Whatever field of coding you are interested in, I can offer the initial training in Website, Game, and
          Application programming.
        </p>
      </div>
    </div>
  </div>

  <div v-if="showItem == 4">
    <ItemNavComponent></ItemNavComponent>
    <div class="softDevItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="coding_prof">
      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>Coding Proficiency</h3>
        <p>Once familiar with the basics, it is time to expand your knowledge and reach out for the next level.</p>
        <p>Based on your field of interest, I will guide you deeper and deeper into the world of code, structures,
          algorithms, and best practices, so you can finalize your first real project.</p>
        <h3>Gain Coding Independence !</h3>
        <p>Based on your interests, goals and experience, this training focusses on one selected coding-language or
          framework and ends with a completed training-project. Get in touch for a personal trainingplan.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ItemNavComponent from './ItemNavComponent.vue';
import MainHeaderComponent from './MainHeaderComponent.vue';
import MainNavComponent from './MainNavComponent.vue';

export default {
    name: 'SoftDevComponent',
    props: {
        msg: String
    }, data() {
        return {
            imgBaseDir: "/img/",
            logo: "/img/logo01.png",
            webdes: "/img/web_des.jpg",
            swdev: "/img/sw_dev.jpg",
            basic_skills: "/img/basic_skills.jpg",
            coding_start: "/img/coding_start.jpg",
            it_prof: "/img/it_prof.jpg",
            coding_prof: "/img/coding_prof.jpg",
            showItem: 1,
        };
    },
    methods: {},
    components: { MainNavComponent, MainHeaderComponent, ItemNavComponent }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header h1,
header h3 {
  color: aliceblue;
}

.landingNav {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  gap: 20px;
}

li {
  text-align: left;
}

.softDevItems {
  margin: -40px auto;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  height: 50vh;

}



a {
  color: #42b983;
}

.hello {
  background-color: black;
  color: white;
}

header img {
  height: 15vh;
  align-self: center;
  flex: 0 0 auto;
}

.imgWebdes {
  height: inherit;
  align-self: flex-start;

}

.itemBox {
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: start;
}

.textBox {
  padding: 5px;
  background-color: none;
}</style>
