<template>
   <div class="itemsNav row">
      <button class="col btn" :class = "this.$parent.showItem==1?'btn-success':'btn-primary'" @click="this.$parent.showItem = 1">Web</button>
      <button class="col btn" :class = "this.$parent.showItem==2?'btn-success':'btn-primary'" @click="this.$parent.showItem = 2">SoftwareDev</button>
      <!-- <button class="btn btn-primary" @click="this.$parent.currPage = 3">Web</button> -->
    </div>
</template>

<script>
export default {
  name: 'SWNavComponent',
  
  

  props: {
    msg: String,
    activePage: String,
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
    methods:{
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .itemsNav {
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  gap: 10px;
}
</style>
