<template>
   <div class="itemsNav row">
      <button class="col-5 col-sm btn" :class = "this.$parent.showItem==1?'btn-success':'btn-primary'" @click="this.$parent.showItem = 1">Basic Skills</button>
      <button class="col-5 col-sm btn" :class = "this.$parent.showItem==2?'btn-success':'btn-primary'" @click="this.$parent.showItem = 2">IT Proficiency</button>
      <button class="col-5 col-sm btn" :class = "this.$parent.showItem==3?'btn-success':'btn-primary'" @click="this.$parent.showItem = 3">Coding Basics</button>
      <button class="col-5 col-sm btn" :class = "this.$parent.showItem==4?'btn-success':'btn-primary'" @click="this.$parent.showItem = 4">Coding Proficiency</button>
    </div>
</template>

<script>
export default {
  name: 'ItemNavComponent',
  
  

  props: {
    msg: String,
    activePage: String,
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
    methods:{
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .itemsNav {
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  gap: 10px;
}
</style>
