<template>
  <MainHeaderComponent msg="Blog"></MainHeaderComponent>
  <MainNavComponent></MainNavComponent>
  <div class="itemsNav row">
      <button class="col-5 col-sm btn btn-primary"  @click="this.$parent.showItem = 1">Basic Skills</button>
      <button class="col-5 col-sm btn btn-primary"  @click="this.$parent.showItem = 2">IT Proficiency</button>
      <button class="col-5 col-sm btn btn-primary"  @click="this.$parent.showItem = 3">Coding Basics</button>
    </div>
  <BlogItem_01></BlogItem_01>
  <BlogItem_02></BlogItem_02>
  <BlogItem_03></BlogItem_03>

</template>

<script>
import MainHeaderComponent from "./MainHeaderComponent.vue";
import MainNavComponent from "./MainNavComponent.vue";
import BlogItem_01 from "./blogItems/BlogItem_01.vue";
import BlogItem_02 from "./blogItems/BlogItem_02.vue";
import BlogItem_03 from "./blogItems/BlogItem_03.vue";


export default {
  name: 'BlogComponent',
  
  components: {
    BlogItem_01,
    BlogItem_02,
    BlogItem_03,
    MainNavComponent,
    MainHeaderComponent
},

  props: {
    msg: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
    methods:{
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

header h1,
header h3 {
  color: aliceblue;
}
.itemsNav {
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  gap: 10px;
}
a {
  color: #42b983;
}


</style>
