<template>
  <div class="blogItem">
    
  </div>
</template>

<script>
export default {
  name: 'BlogItem03',



  props: {
    msg: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
  methods: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.blogItem {
  text-align: left;
  background-color: white;
  color: Blue;
  border-radius: 15px;
  padding: 10px;
  width: 60vw;
  margin: -40px auto;
}



a {
  color: #42b983;
}

</style>
